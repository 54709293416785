/* eslint-disable no-unused-vars */
import { EBusinessProposalStatus, EContractStatus, EInvoiceStatus, ELossReason, EMaritalStatus, EProposalOrigin, EProposalStatus, EStage, ETermStatus } from '~/graphql/types'
import { MonthsNumber } from './types'

const PROPOSAL_STATUS: { [x in EProposalStatus]: string } = {
  documentation: 'Documentação',
  formalization: 'Formalização',
  finalized: 'Contratado',
  canceled: 'Cancelado'
}

const MODULES_ACTIONS: { [x in string]: string } = {
  create: 'Criar',
  updated: 'Alterar',
  read: 'Ver',
  delete: 'Excluir'
}

const FIELDS_FORM: { [x in string]: string } = {
  zipcode: 'CEP',
  street: 'Rua',
  number: 'Número',
  state: 'Estado',
  city: 'Cidade',
  neighborhood: 'Bairro',
  complement: 'Complemento',

  personType: 'Tipo de pessoa',
  name: 'Nome',
  document: 'Documento',
  email: 'E-mail',
  phone: 'Telefone',
  maritalStatus: 'Estado civil',
  nationality: 'Nacionalidade',
  uf: 'Estado',
  dealership: 'Concessionária',
  dealershipId: 'Concessionária',
  dealershipName: 'Concessionária',
  averageEnergyBillValue: 'Valor médio de consumo',
  customerPfDocumentWithPhotoFront: 'Anexar RG ou CNH (Frente)',
  customerPjRepresentativeDocumentWithPhotoFront: 'Anexar RG ou CNH (Frente)',
  customerPfDocumentWithPhotoBack: 'Anexar RG ou CNH (Verso)',
  customerPjRepresentativeDocumentWithPhotoBack: 'Anexar RG ou CNH (Verso)',
  customerPjSocialContract: 'Contrato social',
  customerPjDocumentCard: 'Cartão CNPJ',
  energyBill: 'Ultima fatura de energia',
  identificationNumber: 'Número de identificação'
}

const MARITAL_STATUS: { [x in EMaritalStatus]: string } = {
  divorced: 'Divorciado(a)',
  married: 'Casado(a)',
  single: 'Solteiro(a)',
  widowed: 'Viúvo(a)'
}

const USER_MODULES: { [x in string]: string } = {
  users: 'Usuários',
  accessControl: 'Perfis',
  dealership: 'Concessionária',
  documentsign: 'Documentos',
  files: 'Arquivos',
  notification: 'Notificações',
  customers: 'Clientes',
  sellers: 'Vendedores',
  proposals: 'Propostas',
  powerGenerator: 'Geradores',
  powerPlants: 'Usinas',
  consortiums: 'Consórcios',
  contractNotes: 'Notas de contrato',
  partner: 'Parceiro',
  contracts: 'Contratos'
}

export function translateProposalStatus(status: EProposalStatus) {
  return PROPOSAL_STATUS[status] ?? status
}

const BUSINESS_PROPOSAL_STATUS: { [x in EBusinessProposalStatus]: string } = {
  allocated: 'Reservado',
  approved: 'Aprovado',
  finalized: 'Contratado',
  inAnalysis: 'Em análise',
  rejected: 'Recusado',
  canceled: 'Cancelado'
}
export const translateBusinessProposalStatus = (status: EBusinessProposalStatus) => {
  return BUSINESS_PROPOSAL_STATUS[status] ?? status
}

export function translateMaritalStatus(status?: EMaritalStatus) {
  if (!status) return ''
  return MARITAL_STATUS[status] ?? status
}

export function translateFieldsInput(status: string) {
  return FIELDS_FORM[status] ?? status
}

const CONTRACT_STATUS: { [x in EContractStatus]: string } = {
  sent: 'Enviado',
  reserved: 'Reservado',
  signatures: 'Assinaturas',
  deactivated: 'Desativado',
  inDeactivation: 'Em desativação',
  readyToSend: 'Pronto para envio',
  cancellationRequested: 'Cancelamento solicitado',
  active: 'Ativo'
}
export const translateContractStatus = (status: EContractStatus) => {
  return CONTRACT_STATUS[status] ?? status
}

const CUSTOMER_STATUS: { [x in string]: string } = {
  pending: 'Inativo',
  completed: 'Ativo'
}
export const translateCustomerStatus = (status: string) => {
  return CUSTOMER_STATUS[status] ?? status
}

export function modulesActionsTranslate(status: string) {
  return MODULES_ACTIONS[status] ?? status
}

export function userModulesTranslate(status: string) {
  return USER_MODULES[status] ?? status
}

const TERM_STATUS: { [x in ETermStatus]: string } = {
  signed: 'Assinado',
  canceled: 'Cancelado',
  replaced: 'Substituído',
  created: 'Aguardando assinatura',
  refused: 'Recusado',
  uploaded: 'Em análise',
  requestedSignature: 'Aguardando assinatura'
}
export const translateTermStatus = (status: ETermStatus) => {
  return TERM_STATUS[status] ?? status
}

export enum EDigitalAccountStatus {
  'pending' = 'pending',
  'approved' = 'approved',
  'canceled' = 'canceled',
  'blocked' = 'blocked',
  'inactive' = 'inactive',
}

const DIGITAL_ACCOUNT_STATUS = {
  pending: 'Pendente',
  approved: 'Aprovado',
  canceled: 'Cancelado',
  blocked: 'Bloqueado',
  inactive: 'Inativo',
}

export const translateDigitalAccountStatus = (status: EDigitalAccountStatus) => {
  return DIGITAL_ACCOUNT_STATUS[status] ?? status
}

export const PROPOSAL_ORIGIN: { [x in EProposalOrigin]: string } = {
  activeCampaign: 'Landing Page',
  internal: 'Desperta Energia',
  partner: 'Proposta manual',
  partnerApi: 'Integração API',
  partnerLink: 'Link de indicação',
  website: 'Website Desperta'
}

export const PROPOSAL_ORIGIN_TO_TABLE: { [x in EProposalOrigin]: string } = {
  activeCampaign: 'Cliente',
  internal: 'Backoffice',
  partner: 'Parceiro',
  partnerApi: 'Parceiro',
  partnerLink: 'Parceiro',
  website: 'Cliente'
}

export const translateProposalContractOrigin = (status: EProposalOrigin, origin: { [x in EProposalOrigin]: string }) => {
  return origin[status] ?? status
}

export const CONTRAC_INVOICE: { [x in EInvoiceStatus]: string } = {
  canceled: 'Expirado',
  late: 'Atrasado',
  paid: 'Pago',
  pending: 'Pendente',
  requested: 'Requisitado',
  requestPayment: 'Aguardando pagamento'
}

export const translateContractInvoice = (status: EInvoiceStatus, invoice: { [x in EInvoiceStatus]: string }) => {
  return invoice[status] ?? status
}

export const MONTH_BY_NUMBER: { [x in MonthsNumber]: string } = {
  1: 'Jan',
  '01': 'Jan',
  2: 'Fev',
  '02': 'Fev',
  3: 'Mar',
  '03': 'Mar',
  4: 'Abr',
  '04': 'Abr',
  5: 'Mai',
  '05': 'Mai',
  6: 'Jun',
  '06': 'Jun',
  7: 'Jul',
  '07': 'Jul',
  8: 'Ago',
  '08': 'Ago',
  9: 'Set',
  '09': 'Set',
  10: 'Out',
  11: 'Nov',
  12: 'Dez',
}

export const monthNameByMonthNumber = (monthNumber: MonthsNumber) => {
  return MONTH_BY_NUMBER[monthNumber] ?? monthNumber
}

const LOSS_REASON: { [x in ELossReason]: string } = {
  beneficiaryDueToLowIncome: 'Beneficiado por baixa renda',
  duplicateLead: 'Lead duplicado',
  insufficientInvoiceAmount: 'Valor da fatura insuficiente',
  leadCreatedForTesting: 'Lead criado para testes',
  noContactReturned: 'Não retorna o contato',
  noInterestLostInterest: 'Não tem/perdeu o interesse',
  other: 'Outros',
  weDoNotServicePfInTheRegion: 'Não atendemos PF na região',
  weDoNotServiceTheRegionPjOrPf: 'Não atendemos a região (PF ou PJ)'
}

export const translateLossReason = (status: ELossReason) => {
  return LOSS_REASON[status] ?? status
}

const STAGE_FUNNEL: { [x in EStage]: string } = {
  awaitingSignature: 'Aguardando assinatura',
  contactReestablished: 'Contato retomado',
  firstContactAttempt: '1ª tentativa de contato',
  lead: 'Lead',
  legalAssembly: 'Jurídico/assembleia',
  lost: 'Perdido',
  negotiating: 'Negociando',
  negotiationCoolingDown: 'Negociação esfriando',
  opportunity: 'Oportunidade',
  proposalContracted: 'Proposta contratada',
  secondContactAttempt: '2ª tentativa de contato',
  thirdContactAttempt: '3ª tentativa de contato',
}

export const translateStageFunnel = (status: EStage) => {
  return STAGE_FUNNEL[status] ?? status
}