import React, { useEffect, useState } from 'react'
import { useMediaQuery } from '@mui/material'

import { theme } from '~/utils'
import { useAuth, useSidebar } from '~/contexts'
import { TSidebarItem } from '~/components/Layout'
import { ResponsiveSidebar, SidebarContent } from './components'

export type SidebarProps = {
  isDrawerOpen: boolean
  sidebarItems: TSidebarItem[]
  toggleSidebar: () => void
}

export type Collapsibles = {
  clients: boolean
  proposals: boolean
  dealerships: boolean
  generators: boolean
  powerPlant: boolean
  users: boolean
  consortium: boolean
}

const COLLAPSIBLES_INITIAL_VALUES: Collapsibles = {
  clients: false,
  proposals: false,
  dealerships: false,
  generators: false,
  powerPlant: false,
  users: false,
  consortium: false,
}

export const Sidebar: React.FC<SidebarProps> = ({ isDrawerOpen, sidebarItems, toggleSidebar }) => {
  const { logout } = useAuth()
  const { setSidebar } = useSidebar()
  const [collapsibles, setCollapsibles] = useState<Collapsibles>(COLLAPSIBLES_INITIAL_VALUES)
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const toggleCollapsible = (collapsibleName: keyof Collapsibles) => {
    setCollapsibles(prevState => ({
      ...prevState,
      [collapsibleName]: !prevState[collapsibleName]
    }))
  }

  useEffect(() => {
    isMobile && setSidebar(false)
  }, [isMobile])

  return (
    <>
      {isMobile ? (
        <ResponsiveSidebar
          signOut={logout}
          collapsibles={collapsibles}
          isDrawerOpen={isDrawerOpen}
          sidebarItems={sidebarItems}
          toggleSidebar={toggleSidebar}
          toggleCollapsible={toggleCollapsible}
        />
      ) : (
        <SidebarContent
          signOut={logout}
          collapsibles={collapsibles}
          isDrawerOpen={isDrawerOpen}
          sidebarItems={sidebarItems}
          toggleCollapsible={toggleCollapsible}
        />
      )}
    </>
  )
}
