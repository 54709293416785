import { PropsWithChildren } from 'react'
import { Box, Divider, Typography, useMediaQuery } from '@mui/material'
import { useCustomerFlow } from '~/contexts'
import { Card, ContentTitle, CustomerStatus } from '~/components'
import { formatDate, theme } from '~/utils'
import { IconUser } from '@tabler/icons-react'

export type CustomerFlowLayoutProps = {
  nextButtonLabel?: string
}
export const CustomerFlowLayout: React.FC<PropsWithChildren<CustomerFlowLayoutProps>> = (props) => {
  const { children } = props
  const { customer } = useCustomerFlow()
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Card>
      <ContentTitle
        style={{ padding: '0px 0px 24px 0px' }}
        title={customer ? 'Detalhes do cliente' : 'Cadastrar cliente'}
        breadcrumbLinks={{ currentLink: 'Cadastrar cliente', links: [{ href: '/app/clients/create', label: 'Clientes' }] }}
        description={customer ? 'Acompanhe e dê continuidade ao cadastro do cliente' : 'Complete os dados para criar um novo cliente'}
        rightContent={customer ? (
          <>
            <Box sx={{ display: 'flex', flexFlow: 'column', gap: '.6rem', paddingTop: '1rem', alignItems: 'flex-end' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '.6rem' }}>
                <Typography color={theme.palette.grey[400]} fontSize='.9rem'>Status do cadastro: </Typography>
                <CustomerStatus status={customer.status} />
              </Box>
              <Typography color={theme.palette.grey[400]} fontSize='.9rem'>Cadastrado em: <b>{formatDate(customer?.createdAt)}</b></Typography>
              {customer?.createdBy && <Typography color={theme.palette.grey[400]} fontSize='.95rem' fontWeight={500}>por: {customer?.createdBy}</Typography>}
            </Box>
          </>
        ) : undefined
        }
      />

      <Divider sx={{ marginBottom: 3 }} />

      <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
        {customer && (
          <>
            <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center', flexDirection: isLowerMd ? 'column' : 'row', gap: isLowerMd ? 4 : 0 }}>
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', flexDirection: isLowerMd ? 'column' : 'row' }}>
                <Box sx={{ height: 40, width: 40, padding: 1, borderRadius: 40, background: 'rgba(255, 238, 148, 1)', display: 'flex', alignItems: 'center' }}>
                  <IconUser color='black' />
                </Box>
                <Typography fontSize={isLowerMd ? '1.1rem' : '1.5rem'} variant='h3' fontWeight={500}>{customer?.name}</Typography>
              </Box>
            </Box>

            <Divider sx={{ marginTop: 3, marginBottom: 3 }} />
          </>
        )}
        {children}
      </Box>
    </Card>
  )
}
