import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Card, useMediaQuery } from '@mui/material'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'
import { ContentTitle } from '~/components/ContentTitle'
import { Input } from '~/components/Form/Input'
import { EUserType, UserCreateInput, useCreateUserMutation, useUpdateUserMutation, useUserGetQuery } from '~/graphql/types'
import { useForm, FormProvider } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import { FullPageLoader } from '~/components/FullPageLoader'
import { useUsers } from '~/contexts'
import { UserData } from '~/contexts/users/types'
import { Select } from '~/components/Form/Select'
import { userTypeOptions } from '~/utils/options'
import { clearString, theme } from '~/utils'
import { GridColumn } from '~/styles'
import { IconCheck } from '@tabler/icons-react'

const schemaCreate = yup
  .object({
    name: yup.string().required(),
    password: yup.string().required(),
    email: yup.string().email().required(),
    type: yup.string().required(),
    status: yup.string().required(),
    accessControlId: yup.string().required(),
  })
  .required()

const schemaUpdate = yup
  .object({
    name: yup.string().required(),
    password: yup.string(),
    email: yup.string().email().required(),
    type: yup.string().required(),
    status: yup.string().required(),
    accessControlId: yup.string().required(),
  })
  .required()

export const UsersCreateEdit: React.FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { selectedUser, setSelectedUser, setUsers, accessControls } = useUsers()
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const isEdit = Boolean(selectedUser?._id && id)

  const formMethods = useForm<UserCreateInput>({ resolver: yupResolver(isEdit ? schemaUpdate : schemaCreate) })

  const accessControlOptions = accessControls.map(item =>({ label: item.name, value: item._id }))

  const { refetch: refetchUserGet, loading } = useUserGetQuery({
    variables: {
      id: id || ''
    },
    skip: Boolean(!id),
    onCompleted(data) {
      setSelectedUser(data.userGet as UserData)
    },
    onError: () => {
      toast.error('Não foi possível carregar esta usina.')
    }
  })

  useEffect(() => {
    if(id) {
      refetchUserGet()
    }
    setSelectedUser(null)
  }, [id])

  const [createUser, { loading: createIsLoading }] = useCreateUserMutation({
    onCompleted: (data) => {
      setUsers(prev => [...prev, data.createUser])
      toast.success('Um novo usuário foi cadastrado!')
      navigate('/app/users')
    },
    onError: (error) => {
      toast.error(error.message === 'Email already registered' ? 'E-mail já cadastrado' : 'Ocorreu um erro ao cadastrar um novo usuário.')
    }
  })
  
  const [updateUser, { loading: updateIsLoading }] = useUpdateUserMutation({
    onCompleted: (data) => {
      setUsers(prev => prev.map(item => item._id === data.updateUser._id ? data.updateUser : item))
      setSelectedUser(null)
      toast.success(`O usuário ${data.updateUser.name} foi alterado!`)
      navigate('/app/users')
    },
    onError: () => {
      toast.error('Ocorreu um erro ao alterar um usuário.')
    }
  })

  function onSubmit(data: UserCreateInput) {
    const accessControlName = accessControlOptions.find(item => item.value === data.accessControlId)
    const username = data.name

    if(id && selectedUser) {
      updateUser({ 
        variables: { 
          id, 
          params: { 
            phone: clearString(data.phone), 
            accessControlName: accessControlName?.label || '',
            accessControlId: data.accessControlId,
            email: data.email,
            name: data.name,
            status: data.status,
            type: data.type
          }
        } 
      })
    } else {
      createUser({ variables: { params: { ...data, phone: clearString(data.phone), accessControlName: accessControlName?.label || '', username } } })
    }
  }

  useEffect(() => {
    if(id && selectedUser) {
      formMethods.setValue('name', selectedUser?.name)
      formMethods.setValue('email', selectedUser?.email)
      formMethods.setValue('type', selectedUser?.type || EUserType.internal)
      formMethods.setValue('phone', selectedUser?.phone)
      formMethods.setValue('status', selectedUser?.status)
      formMethods.setValue('accessControlId', selectedUser?.accessControlRef.id)
    } else {
      formMethods.reset()
    }
  }, [selectedUser])

  if(id && loading) return <FullPageLoader />

  return (
    <div>
      <Card sx={{ background: 'white', padding: '0 1rem', borderRadius: '1rem' }}>
        <ContentTitle
          title={`${id && selectedUser ? `Alterar o usuário ${selectedUser.name}` : 'Cadastrar usuário'}`}
          description={`Complete os dados para ${id && selectedUser ? 'alterar' : 'cadastrar um'} Usuário`}
          breadcrumbLinks={{ currentLink: `${id && selectedUser ? 'Alterar' : 'Cadastrar'} Usuário`, links: [{ href: '/app/users', label: 'Usuários' }] }}
        />

        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit((data) => onSubmit(data))}>
            <Box minHeight='100vh' maxWidth='920px' display='flex' flexDirection='column' gap='1rem'>
              <GridColumn responsive={[{ breakWidth: theme.breakpoints.values.md, columns: 1 }]} columns={2}>
                <Input name='name' label='Nome' />
                <Input name='email' label='E-mail' />
                <Input mask='phone' name='phone' label='Telefone' />
                <Select options={accessControlOptions} name='accessControlId' label='Perfil de acesso' />
              </GridColumn>
              <Select options={userTypeOptions} name='type' label='Tipo de usuário' />
              <Select options={[{ value: 'active', label: 'ativo' }, { value: 'inactive', label: 'Inativo' }]} name='status' label='Status' />

              {!isEdit && 
                <Input type='password' name='password' label='Senha' />
              }

              <Box sx={{ 
                width: '100%', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'space-between', 
                flexFlow: isLowerSm ? 'column-reverse' : 'row', 
                gap: isLowerSm ? '1rem' : '0' 
              }}>
                  
                <Link style={{ width: '100%' }} to='/app/users'>
                  <Button sx={{ maxWidth: isLowerSm ? '100%' : '160px' }} fullWidth disabled={createIsLoading || updateIsLoading} color='secondary'>
                    Cancelar
                  </Button>
                </Link>

                <Button
                  fullWidth
                  sx={{ maxWidth: isLowerSm ? '100%' : '200px' }}
                  startIcon={<IconCheck />}
                  disabled={createIsLoading || updateIsLoading} type='submit'>
                  {id && selectedUser ? 'Alterar' : 'Cadastrar'} Usuário
                </Button>
              </Box>
            </Box>
          </form>
        </FormProvider>
      </Card>
    </div>
  )
}
