import { Box, Button, Drawer, IconButton, Typography } from '@mui/material'
import { IconX } from '@tabler/icons-react'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { CheckboxMultiple } from '~/components/Form/CheckboxMultiple'
import { Input } from '~/components/Form/Input'
import { SearchQueysData, useQueryString } from '~/hooks'
import { personTypeOptions } from '~/utils/options'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

type FilterCustomerDrawerProps = {
  drawerIsOpen: boolean
  setDrawerIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  toggleDrawer: () => void
  queryKeys: SearchQueysData[]
}

type FilterData = {
  name?: string,
  person?: string[],
  begin?: string,
  end?: string
}

const schema = yup
  .object({
    begin: yup.string().test('isValid', (value, validation) => {

      if(value && Boolean(!validation.parent.end)) {
        return validation.createError({
          message: 'Este campo é obrigatório.',
          path: 'end'
        })
      }
      return true
    }),
    end: yup.string()
      .test('isValid', (value, validation) => {
        if(value && Boolean(!validation.parent.begin)) {
          return validation.createError({
            message: 'Este campo é obrigatório.',
            path: 'begin'
          })
        }
        return true
      }),
  })
  .required()

export const FilterCustomerDrawer: React.FC<FilterCustomerDrawerProps> = ({ drawerIsOpen, queryKeys, setDrawerIsOpen, toggleDrawer }) => {
  const formMethods = useForm({
    resolver: yupResolver(schema)
  })

  const { searchQuerys, setSearchParams } = useQueryString()
  const queryValues = searchQuerys(queryKeys)

  useEffect(() => {
    if(drawerIsOpen) {
      formMethods.setValue('begin', queryValues.begin)
      formMethods.setValue('end', queryValues.end)
      formMethods.setValue('person', queryValues.person)
    }
  }, [drawerIsOpen])

  const handleFilters = (filters: FilterData) => {
    setDrawerIsOpen(false)
    setSearchParams(state => {
      state.set('page', '0')
      if(filters?.person && filters?.person?.length > 0 || queryValues?.person?.length > 0) {
        state.set('person', JSON.stringify(filters.person) || queryValues.person)
      } else {
        state.delete('person')
      }
      filters.begin  || queryValues.begin  ? state.set('begin', filters.begin || queryValues.begin) : state.delete('begin')
      filters.end  || queryValues.end  ? state.set('end', filters.end || queryValues.end) : state.delete('end')
      return state
    })
  }

  const onRemoveFilters = () => {
    setDrawerIsOpen(false)
    setSearchParams(state => {
      state.set('page', '0')
      state.delete('person')
      state.delete('begin')
      state.delete('end')
      return state
    })
  }

  return (
    <Drawer PaperProps={{ sx: { maxWidth: '560px', width: '100%', padding: '2rem' } }} anchor='right' open={drawerIsOpen} onClose={toggleDrawer}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography fontWeight={500} variant='h5'>Filtrar por</Typography>
        <IconButton type='button' onClick={() => toggleDrawer()}>
          <IconX />
        </IconButton>
      </Box>

      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit((formData) => handleFilters(formData))}>
          <Typography sx={{ paddingTop: '1rem' }} fontWeight={500}>Data de solicitação</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', padding: '1rem 0 2rem 0' }}>
            <Input name='begin' type='date' />
            <Typography>Até</Typography>
            <Input name='end' type='date' />
          </Box>
          <Typography fontWeight={500}>Status</Typography>
          <CheckboxMultiple name='person' options={personTypeOptions} />
          <Box sx={{ display: 'flex', padding: '2rem 0', gap: '1rem', justifyContent: 'flex-end' }}>
            <Button onClick={() => onRemoveFilters()} type='button' fullWidth color='secondary'>Limpar</Button>
            <Button type='submit' fullWidth color='primary'>Aplicar</Button>
          </Box>
        </form>
      </FormProvider>
    </Drawer>
  )
}
