import { createContext, useContext, useEffect, useState } from 'react'
import { DealershipContextData, DealershipProviderProps, dealershipListData, dealershipData } from './types'
import { useDealershipListLazyQuery } from '~/graphql/types'

const DealershipContext = createContext({} as DealershipContextData)

export const DealershipProvider: React.FC<DealershipProviderProps> = ({ children }) => {
  const [dealerships, setDealerships] = useState<dealershipListData>([])
  const [selectedDealership, setSelectedDealership] = useState<dealershipData | null>(null)
  
  const [getDealerships, { data, loading: dealershipListIsLoading }] = useDealershipListLazyQuery()

  useEffect(() => {
    if(data) {
      setDealerships(data.dealershipList.data as dealershipListData)
    }
  }, [data])

  return (
    <DealershipContext.Provider value={{ 
      dealerships,
      setDealerships,
      dealershipListIsLoading,
      selectedDealership,
      setSelectedDealership,
      getDealerships
    }}>{children}</DealershipContext.Provider>
  )
}

export const useDealership = () => useContext(DealershipContext)
