import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import {
  PowerGeneratorProvider,
  PowerPlantProvider,
  useAuth,
  DealershipProvider,
  UsersProvider,
  ConsortiumProvider,
  CustomerFlowProvider,
  ContractProvider,
  AccessControlProvider,
  CustomerContractProvider,
  DiscountTableProvider
} from './contexts'

import {
  AccessControlList,
  ConsortiumCreateEdit,
  ConsortiumList,
  DealershipCreate,
  DealershipList,
  Home,
  Login,
  PowerGeneratorCreateEdit,
  PowerGeneratorList,
  PowerPlantCreateEdit,
  PowerPlantList,
  ProposalCreate,
  ProposalListAnalysis,
  ProposalResolvePendencies,
  ProposalsList,
  RecoverPassword,
  Settings,
  UsersCreateEdit,
  UsersList,
  CustomersCreate,
  CustomersList,
  ContractsList,
  ContractsCreate,
  DiscountTableList,
  DiscountTableCreateEdit,
  AccessControlCreate,
  AccessControlEdit,
} from './pages'
import { Layout, PageLoader } from './components'

const PrivateRoute = () => {
  const { authUser, authUserIsLoading } = useAuth()

  if (authUserIsLoading) return <PageLoader />

  if (authUser?.user) return <Outlet />

  return <Navigate to='/' />
}

export const App = () => {
  return (
    <Routes>
      <Route path='/' element={<Login />} />
      <Route path='/recover-password' element={<RecoverPassword />} />
      <Route path='*' element={<Navigate to='/' />} />

      <Route path='app/*' element={
        <Layout>
          <PrivateRoute />
        </Layout>
      }
      >
        <Route index element={<Home />} />

        <Route path='customers' element={<CustomerFlowProvider><Outlet /></CustomerFlowProvider>}>
          <Route index element={<CustomersList />} />
          <Route path='create' element={<CustomersCreate />} />
          <Route path=':customerId' element={<CustomerContractProvider><CustomersCreate /></CustomerContractProvider>} />
        </Route>

        <Route path='settings' element={<Settings />} />

        <Route path='proposals' element={<Outlet />}>
          <Route index element={<ProposalsList />} />
          <Route path='register' element={<ProposalCreate />} />
          <Route path='analysis' element={<ProposalListAnalysis />} />
          <Route path=':proposalId' element={<ProposalCreate />} />
          <Route path=':proposalId/pendencies' element={<ProposalResolvePendencies />} />
        </Route>

        <Route path='dealerships' element={<DealershipProvider><Outlet /></DealershipProvider>}>
          <Route index element={<DealershipList />} />
          <Route path='create' element={<DealershipCreate />} />
          <Route path='update/:id' element={<DealershipCreate />} />
        </Route>

        <Route path='generators' element={<PowerGeneratorProvider><Outlet /></PowerGeneratorProvider>}>
          <Route index element={<PowerGeneratorList />} />
          <Route path='create' element={<PowerGeneratorCreateEdit />} />
          <Route path='update/:id' element={<PowerGeneratorCreateEdit />} />
        </Route>

        <Route path='power-plants' element={<PowerPlantProvider><Outlet /></PowerPlantProvider>}>
          <Route index element={<PowerPlantList />} />
          <Route path='create' element={<PowerPlantCreateEdit />} />
          <Route path='update/:id' element={<PowerPlantCreateEdit />} />
        </Route>
 
        <Route path='discount-table' element={<DiscountTableProvider><Outlet /></DiscountTableProvider>}>
          <Route index element={<DiscountTableList />} />
          <Route path='create' element={<DiscountTableCreateEdit />} />
          <Route path='update/:id' element={<DiscountTableCreateEdit />} />
        </Route>

        <Route path='users' element={<UsersProvider><Outlet /></UsersProvider>}>
          <Route index element={<UsersList />} />
          <Route path='create' element={<UsersCreateEdit />} />
          <Route path='update/:id' element={<UsersCreateEdit />} />
        </Route>

        <Route path='consortiums' element={<ConsortiumProvider><Outlet /></ConsortiumProvider>}>
          <Route index element={<ConsortiumList />} />
          <Route path='create' element={<ConsortiumCreateEdit />} />
          <Route path='update/:id' element={<ConsortiumCreateEdit />} />
        </Route>

        <Route path='contracts' element={<ContractProvider><Outlet /></ContractProvider>}>
          <Route index element={<ContractsList />} />
          <Route path='create' element={<ContractsCreate />} />
          <Route path=':contractId' element={<ContractsCreate />} />
        </Route>

        <Route path='access-control' element={<AccessControlProvider><Outlet /></AccessControlProvider>}>
          <Route index element={<AccessControlList />} />
          <Route path='create' element={<AccessControlCreate />} />
          <Route path='update/:id' element={<AccessControlEdit />} />
        </Route>

      </Route>

      <Route path='*' element={<Navigate to='/app' />} />
    </Routes>
  )
}
