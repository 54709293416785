import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Card, IconButton, InputAdornment, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { IconArrowRight, IconPlus, IconSearch } from '@tabler/icons-react'
import { Column, useFilters, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import { SearchQueysData, useDebounce, usePermission, useQueryString, useToggle } from '~/hooks'
import { ContractStatus, FilterButton, TableApiV2, TableHeaderApi } from '~/components'
import { ContentTitle } from '~/components/ContentTitle'
import { PROPOSAL_ORIGIN, PROPOSAL_ORIGIN_TO_TABLE, displayCpfCnpj, ifIsDocumentReturnFormated, theme, translateProposalContractOrigin, trimString } from '~/utils'
import { useContractListQuery } from '~/graphql/types'
import { FilterContractListDrawer } from './components'

export const ContractsList: React.FC = () => {
  const navigate = useNavigate()

  const queryKeys: SearchQueysData[] = [
    { key: 'pendencies', defaultValue: [], parseValue: true },
    { key: 'status', defaultValue: [], parseValue: true },
    { key: 'origin', defaultValue: [], parseValue: true },
    { key: 'name', defaultValue: '' },
    { key: 'begin', defaultValue: '' },
    { key: 'end', defaultValue: '' },
  ]
  
  const { searchQuerys, paginationQuerySize, searchParams, setSearchParams, page, size } = useQueryString()

  const queryValues = searchQuerys(queryKeys)

  const isLowerLg = useMediaQuery(theme.breakpoints.down('lg'))
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const { isTrue: drawerIsOpen, toggle: toggleDrawer, setIsTrue: setDrawerIsOpen } = useToggle(false)
  const canCreateAndEditContracts = usePermission(['contracts.updated', 'contracts.create'])

  const [filterName, setFilterName] = useState(queryValues.name)

  const debouncedFilterName = useDebounce(filterName, 1000)

  useEffect(() => {
    setSearchParams(state => {
      if(debouncedFilterName) {
        state.set('name', debouncedFilterName)
        state.set('page', '0')
      } else {
        state.delete('name')
      }
      return state
    })
  }, [debouncedFilterName])

  const { data, loading } = useContractListQuery({
    variables: {
      params: {
        pageSize: size,
        pageNumber: page,
        sort: {
          field: 'createdAt',
          order: -1
        },
        ...queryValues.name?.length > 0 ? { nameOrDocument: ifIsDocumentReturnFormated(queryValues.name) } : {},
        ...queryValues.status?.length > 0 ? { inStatus: queryValues.status } : {},
        ...queryValues.pendencies?.length > 0 ? { inPendencies: queryValues.pendencies } : {},
        ...queryValues.origin?.length > 0 ? { inOrigin: queryValues.origin } : {},
        ...queryValues?.begin && queryValues?.end ?
          { dateRange: 
          { startDate: new Date(queryValues.begin),
            endDate: new Date(`${queryValues.end}Z23:59:59`) 
          }
          } 
          : {},
      }
    },
    onCompleted: (result) => {
      setSearchParams(state => {
        state.set('items', String(result.contractList.meta.documentCounts))
        return state
      })
    },
    fetchPolicy: 'no-cache'
  })

  const columns = useMemo((): Column[] => {
    return [
      {
        Header: 'Nome',
        accessor: 'customerRef.name',
        Cell: (props: any) => (
          <Tooltip title={props.value}>
            <Box>
              {trimString(props.value, { maxTextLength: 20, textCutLength: 20 })}
            </Box>
          </Tooltip>
        ) },
      {
        Header: 'Documento',
        accessor: 'customerRef.document',
        Cell: (props) => <>{displayCpfCnpj(props.value)}</>
      },
      {
        Header: 'Origem',
        accessor: 'origin',
        Cell: (props) => (
          <Tooltip placement='bottom-start' title={translateProposalContractOrigin(props.value, PROPOSAL_ORIGIN)}>
            <Typography fontSize='14px' fontWeight={500} color={theme.palette.despertaPurple.light} >{props.value ? translateProposalContractOrigin(props.value, PROPOSAL_ORIGIN_TO_TABLE) : props.value}</Typography>
          </Tooltip>
        ) },
      {
        Header: 'Parceiro',
        accessor: 'indicationRef.partner.name',
        Cell: (props) => <>{props.value || '-'}</>,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (props) => <ContractStatus status={props.value} />
      },
      {
        Header: 'Acessar',
        accessor: '_id',
        disableSortBy: true,
        Cell: (props) => <IconButton onClick={() => navigate(`/app/contracts/${props.value}`)}><IconArrowRight /></IconButton>
      },
    ]
  }, [])

  const dataWithMemo = useMemo(() => data?.contractList.data || [], [data])

  const defaultColumnHiddens = isLowerLg ? 
    ['customerRef.email', 'indicationRef.partner.name'].concat(isLowerMd ? ['origin'] : '').concat(isLowerSm ? ['customerRef.document'] : '') 
    : []

  const tableMethods = useTable({
    columns,
    data: dataWithMemo,
    defaultColumn: {
      Filter: <></>
    },
    manualPagination: true,
    initialState: {
      hiddenColumns: defaultColumnHiddens
    }
  },
  useFilters,
  useSortBy,
  usePagination,
  useRowSelect
  )

  return (
    <div>
      <Card sx={{ background: 'white', padding: '0 1rem', borderRadius: '1rem' }}>
        <ContentTitle
          title='Lista de contratos'
          description='Visualize e gerencie os contratos de seus clientes'
          breadcrumbLinks={{ currentLink: 'Listar contratos', links: [{ href: '/app/contracts', label: 'Contratos' }] }}
          rightContent={<Button disabled={!canCreateAndEditContracts} onClick={() => navigate('/app/contracts/create')} startIcon={<IconPlus />}>Gerar contrato</Button>}
        />
        <TableHeaderApi
          startElement={(
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '1rem', width: '100%', flexFlow: isLowerSm ? 'column-reverse' : 'row' }}>
              <TextField
                fullWidth
                sx={{ maxWidth: '400px' }}
                value={filterName}
                onChange={(e) => setFilterName(e.target.value)}
                placeholder='Procurar contrato por nome ou CPF/CNPJ'
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position='end'>
                        <IconSearch />
                      </InputAdornment>
                    </>
                  )
                }}
              />
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                <FilterButton onCustomClear={() => setFilterName('')} filtersToClear={queryKeys} toggleDrawer={toggleDrawer} filterCounter={searchParams.size - paginationQuerySize} />
              </Box>

              <FilterContractListDrawer queryKeys={queryKeys}  drawerIsOpen={drawerIsOpen} setDrawerIsOpen={setDrawerIsOpen} toggleDrawer={toggleDrawer} />
  
            </Box>
          )}
        />
        <TableApiV2
          isLoading={loading}
          tableMethods={tableMethods}
          columns={columns}
        />
      </Card>
    </div>
  )
}
