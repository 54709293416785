import { useMemo } from 'react'
import { toast } from 'react-toastify'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { ContentCopy, Share } from '@mui/icons-material'
import { theme, trimString } from '~/utils'
import { ContractSign } from '~/assets/icons'
import { copyToClipboard } from '~/utils/clipboard'
import { TermOfAdhesionStatus } from '~/components'
import { TermOfAdhesionModel } from '~/graphql/types'

{/* WARNING - Dentro da proposta o `termOfAdhesion` na verdade está com o nome errado, na verdade é o `termOfManagement` */}

type ProposalManagementTermCardProps = {
  adhesionTerm?: TermOfAdhesionModel | null
}

export const ProposalManagementTermCard: React.FC<ProposalManagementTermCardProps> = ({ adhesionTerm }) => {
  const hasSigned = Boolean(adhesionTerm?.signedAt)
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))

  const signers = useMemo(() => {
    return adhesionTerm?.signers.map(signer => (
      <Box gap={1} display='flex' flexDirection={isLowerMd ? 'column' : 'row'} key={`${signer.email}.${signer.name}`}>
        {/* {!isLowerMd && ( */}
        <Typography display='inline-block'>
          • Assinante:
        </Typography>
        {/* )} */}
        <Typography color='#999999'>
          {signer.name}
        </Typography>
      </Box>
    ))
  }, [adhesionTerm])

  const onCopyToClipboard = (link?: string | null) => {
    copyToClipboard(link)
    toast.success('Link copiado!', {
      position: 'top-right',
      autoClose: 3000
    })
  }

  return (
    <Box>
      <Box sx={{
        gap: 3,
        padding: 3,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid #E6E6E6',
        borderLeft: `6px solid ${hasSigned ? '#00A86B' : '#F9DC45'}`,
        marginTop: '1rem'
      }}>
        {!isLowerSm && 
        <ContractSign />
        }

        <Box sx={{
          gap: 3,
          display: 'flex',
          flexDirection: 'column'
        }}>
          <Box sx={{
            gap: 3,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            flexWrap: 'wrap'
          }}>
            <Typography>Assinatura do cliente</Typography>
            <TermOfAdhesionStatus signedAt={adhesionTerm?.signedAt} />
            {!isLowerMd && 
            <Typography color='#999999'>{hasSigned ? 'Assinado pelo cliente' : 'Aguardando cliente assinar'}</Typography>
            }
          </Box>

          {signers}

          {adhesionTerm?.signerUrl && (
            <Box gap={2} display='flex' flexDirection='column'>
              <Box flexDirection='row' display='flex' alignItems='center' gap={1}>
                <Typography fontWeight={600}>Compartilhar</Typography>
                <Share color='action' />
              </Box>

              <Typography variant='body2' color='#999999'>Link para termo de gestão (Copie e cole onde desejar):</Typography>
              <Box
                onClick={() => onCopyToClipboard(adhesionTerm?.signerUrl)}
                sx={{
                  display: 'flex',
                  padding: 2,
                  borderRadius: '8px',
                  border: '1px solid #E6E6E6',
                  justifyContent: 'space-between',
                  cursor: 'pointer',
                  alignItems: 'center'
                }}>
                <Typography sx={{ paddingRight: '1rem' }} variant='body2'>
                  {trimString(adhesionTerm.signerUrl, { maxTextLength: isLowerSm ? 20 : 100, textCutLength: isLowerSm ? 20 : 100 })}
                </Typography>

                <ContentCopy color='action' fontSize='small' />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}
