import { InputHTMLAttributes } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormControlLabel, Radio as MuiRadio, RadioGroup, RadioGroupProps, RadioProps, Typography } from '@mui/material'

export type InnerRadioProps = InputHTMLAttributes<HTMLInputElement> & RadioGroupProps & {
  name: string
  options: {
    value: string | boolean
    label?: string
  }[]
  radioProps?: RadioProps
}

export const Radio: React.FC<InnerRadioProps> = ({ name, options, radioProps, disabled, ...rest }) => {
  const { control } = useFormContext()

  const normalizeBoolean = (value: string) => {
    if(value === 'true') return true
    if(value === 'false') return false
    return value
  }

  return (

    <Controller
      name={name}
      control={control}
      defaultValue={options[0].value}
      render={({ field }) => (
        <RadioGroup {...field} {...rest} onChange={(e) => field.onChange(normalizeBoolean(e.target.value))} >
          {options.map((option, index) => {
            return (
              <FormControlLabel
                value={option.value}
                disabled={disabled}
                key={`radio-option-${option.value}-${index}`}
                control={<MuiRadio {...radioProps} />}
                label={<Typography variant='body2' color='grey.400'>{option.label}</Typography>}
              />
            )
          })}
        </RadioGroup>
      )}
    />

  )
}
