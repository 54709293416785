type FormatDateOptions = {
  dateHour?: boolean
}

export function formatDate(isoDate: string, options?: FormatDateOptions) {
  if(!isoDate) return ''
  const date = new Date(!isoDate.includes('Z') ? Number(isoDate) : isoDate)
  if(options?.dateHour) {
    return new Intl.DateTimeFormat('pt-BR', {
      timeZone: 'America/Sao_Paulo',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute:'2-digit',
      second:'2-digit'
    }).format(date)
  } else {
    return new Intl.DateTimeFormat('pt-BR', {
      timeZone: 'America/Sao_Paulo',
    }).format(date)
  }
}

export function formatHour(isoDate: string) {
  if(!isoDate) return ''
  const date = new Date(!isoDate.includes('Z') ? Number(isoDate) : isoDate)
 
  return new Intl.DateTimeFormat('pt-BR', {
    timeZone: 'America/Sao_Paulo',
    hour: '2-digit',
    minute:'2-digit'
  }).format(date)

}

export function formatDateToInput(isoDate: string) {
  if(!isoDate) return ''
  const date = new Date(isoDate).toISOString().substring(0,10)
  return date
}

