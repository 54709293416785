import { Status } from '~/components'

export type TermOfAdhesionStatusProps = {
  signedAt?: string | null | boolean
}
export const TermOfAdhesionStatus: React.FC<TermOfAdhesionStatusProps> = ({ signedAt }) => {
  const hasSigned = Boolean(signedAt)

  return (
    <Status
      color={hasSigned ? '#367154' : '#A16F31'}
      background={hasSigned ? '#C6F6D5' : '#FEFCBF'}
      text={hasSigned ? 'Assinado' : 'Aguardando assinatura'}
    />
  )
}
