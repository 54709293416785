import { toast } from 'react-toastify'
import { Info } from '@mui/icons-material'
import { Box, CircularProgress, Divider, Typography } from '@mui/material'
import { Dialog } from '~/components'
import { useContract } from '~/contexts'
import { Button } from '~/components/Form/Button'
import { ContractModel, useContractRequestTermOfManagementMutation } from '~/graphql/types'
import { useCallback } from 'react'

export type GenerateTermOfManagementDialogProps = {
  isVisible: boolean
  toggleModal: () => void
}

export const GenerateTermOfManagementDialog: React.FC<GenerateTermOfManagementDialogProps> = ({ isVisible, toggleModal }) => {
  const { contract, setContract } = useContract()
  const [requestTermOfManagement, { loading }] = useContractRequestTermOfManagementMutation({
    onError(error) {
      toast.error(error.message)
    },
    onCompleted(data) {
      if(data.contractRequestTermOfManagement) {
        setContract(data.contractRequestTermOfManagement as ContractModel)
      }
      toggleModal()
    },
  })

  const onClick = useCallback(async() => {
    if (contract) {
      await requestTermOfManagement({ variables: { contractId: contract._id } })
    } else {
      toast.error('Nenhum contrato selecionado, tente recarregar a página ou entre em contato com o time de tecnologia.')
    }
  }, [contract])

  return (
    <Dialog
      isVisible={isVisible}
      title='Gerar novo termo de gestão'
      onClose={loading ? undefined : toggleModal}
      titleIcon={<Info fontSize='large' color='primary' />}
    >
      <Box sx={{ flexDirection: 'column', display: 'flex', gap: 3 }}>
        <Divider />
        <Typography>Ao gerar um novo termo de gestão, seu cliente será obrigado a repetir a ação de assiná-lo.</Typography>
        <Typography>Deseja gerar um novo termo de gestão?</Typography>

        <Divider />

        <Box sx={{ flexDirection: 'row', display: 'flex', gap: 3, justifyContent: 'end' }}>
          <Button
            color='secondary'
            disabled={loading}
            onClick={toggleModal}
          >
            Cancelar
          </Button>

          <Button
            color='primary'
            onClick={onClick}
            disabled={loading}
            startIcon={loading ? <CircularProgress size={24} color='inherit' /> : null}
          >
            Gerar novo termo
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
