import { Box, Button, CircularProgress, Divider, Grid, Typography, useMediaQuery } from '@mui/material'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { Card, Status } from '~/components'
import { ContentTitle } from '~/components/ContentTitle'
import { Checkbox } from '~/components/Form/Checkbox'
import { Input } from '~/components/Form/Input'
import { Select } from '~/components/Form/Select'
import { brazilStates } from '~/constants'
import { DealershipCreateDto, EDealershipResolutuionClass, useDealershipCreateMutation, useDealershipGetQuery, useDealershipUpdateMutation } from '~/graphql/types'
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom'
import { useDealership } from '~/contexts/dealerships'
import { useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { usePermission } from '~/hooks'
import { ApolloError } from '@apollo/client'
import { mapErrorToMessage } from '~/utils/errors'
import { dealershipData } from '~/contexts/dealerships/types'
import { FullPageLoader } from '~/components/FullPageLoader'
import { theme, transformMoney } from '~/utils'
import { IconCheck, IconCurrencyDollar, IconLink, IconPercentage } from '@tabler/icons-react'
import { Form } from '~/components/Form/Form'
import { Switch } from '~/components/Form/Switch'

const EMPTY_CLASSES_RESOLUTIONS = [
  { class: EDealershipResolutuionClass.b1, cofinsPercentage: null, icmsPercentage: null, pisPercentage: null, teValueCents: null, tusdValueCents: null },
  { class: EDealershipResolutuionClass.b2, cofinsPercentage: null, icmsPercentage: null, pisPercentage: null, teValueCents: null, tusdValueCents: null },
  { class: EDealershipResolutuionClass.b3, cofinsPercentage: null, icmsPercentage: null, pisPercentage: null, teValueCents: null, tusdValueCents: null }
] as any[]

const schema = yup
  .object({
    name: yup.string().min(3),
    maxDiscountPercentagePf: yup.number().max(100).required(),
    maxDiscountPercentagePj: yup.number().max(100).required(),
    locationUf: yup.string().required().oneOf(brazilStates.map(states => states.value)),
    available: yup.boolean().required(),
    resolutionClasses: yup.array().of(
      yup.object().shape({
        class: yup.mixed<EDealershipResolutuionClass>().oneOf(Object.values(EDealershipResolutuionClass)).required(),
        cofinsPercentage: yup.number().max(100).required(),
        // fcp: yup.number().required(),
        icmsPercentage: yup.number().max(100).required(),
        pisPercentage: yup.number().max(100).required(),
        teValueCents: yup.number().required(),
        tusdValueCents: yup.number().required(),
        // wireBCalculationBaseValueCents: yup.number().required(),
      })
    ),
    resolutionAneel:
      yup.object().shape({
        linkUrl: yup.string().matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          'Digite uma URL de site correto.'
        ).required(),
        // modality: yup.mixed<EDealershipResolutionModality>().oneOf(Object.values(EDealershipResolutionModality)).required(),
        validity: yup.string().required(),
        resolutionHomologed: yup.string().matches(/\d{4}[/]\d{4}/).required(),
      }),
    minimumExceptionValue: yup.object().shape({
      value: yup.string(),
      allowException: yup.boolean()
    })
  })
  .required()

export const DealershipCreate: React.FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { setDealerships, selectedDealership, setSelectedDealership, } = useDealership()

  const canCreateAndEditDealerships = usePermission(['dealership.updated', 'dealership.create'])
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const formMethods = useForm<DealershipCreateDto>({
    resolver: yupResolver(schema),
    defaultValues: {
      resolutionClasses: EMPTY_CLASSES_RESOLUTIONS
    }
  })

  const { fields } = useFieldArray({ control: formMethods.control, name: 'resolutionClasses' })

  const isExeptionAvailable = formMethods.watch('minimumExceptionValue.allowException')
  const isExceptionActive = selectedDealership?.minimumExceptionValue?.allowException

  const { refetch: refetchDealershipGet, loading: dealershipIsLoading } = useDealershipGetQuery({
    variables: {
      id: id || ''
    },
    skip: Boolean(!id),
    onCompleted(data) {
      setSelectedDealership(data.dealershipGet as dealershipData)
    },
    onError: () => {
      formMethods.reset()
      toast.error('Não foi possível carregar esta concessionária.')
    }
  })

  useEffect(() => {
    if (id) {
      refetchDealershipGet()
    }
    setSelectedDealership(null)
    formMethods.reset()
  }, [id])

  const [createDealership, { loading: createLoading }] = useDealershipCreateMutation({
    onCompleted: (data) => {
      setDealerships(prev => [data.dealershipCreate, ...prev])
      toast.success('Uma nova concessionária foi cadastrada!')
      navigate('/app/dealerships')
    },
    onError: (err) => {
      const apolloError = err as ApolloError
      toast.error(mapErrorToMessage(apolloError?.graphQLErrors[0]?.extensions?.code as string, 'Ocorreu um erro ao cadastrar a concessionária.'))
    }
  })

  const [updateDealership, { loading: updateLoading }] = useDealershipUpdateMutation({
    onCompleted: (data) => {
      setDealerships(prev => prev.map(item => item._id === data.dealershipUpdate._id ? data.dealershipUpdate : item))
      setSelectedDealership(null)
      toast.success(`A concessionária ${data.dealershipUpdate.name} foi alterada!`)
      navigate('/app/dealerships')
    },
    onError: () => {
      toast.error('Ocorreu um erro ao alterar a concessionária.')
    }
  })

  function onSubmit(data: DealershipCreateDto) {
    const { minimumExceptionValue } = data

    const updatedParams: DealershipCreateDto = {
      ...data,
      minimumExceptionValue: minimumExceptionValue?.value ? {
        allowException: minimumExceptionValue.allowException,
        value: transformMoney(minimumExceptionValue.value)
      } : null,
      resolutionClasses: data.resolutionClasses?.map(item => ({
        ...item,
        tusdValueCents: Math.round(transformMoney(item.tusdValueCents, 'toCents')),
        teValueCents: Math.round(transformMoney(item.teValueCents, 'toCents')),
        cofinsPercentage: item.cofinsPercentage,
        icmsPercentage: item.icmsPercentage,
        pisPercentage: item.pisPercentage,
      }))
    }

    if (id && selectedDealership) {
      updateDealership({ variables: { params: { _id: id, ...updatedParams } } })
    } else {
      createDealership({ variables: { params: updatedParams } })
    }
  }

  useEffect(() => {
    formMethods.register('resolutionClasses')
    if (id && selectedDealership) {
      formMethods.setValue('resolutionAneel', {
        linkUrl: selectedDealership.resolutionAneel?.linkUrl || '',
        resolutionHomologed: selectedDealership.resolutionAneel?.resolutionHomologed || '',
        validity: selectedDealership.resolutionAneel?.validity || '',
      })

      formMethods.setValue('maxDiscountPercentagePj', selectedDealership?.maxDiscountPercentagePj || 0)
      formMethods.setValue('maxDiscountPercentagePf', selectedDealership?.maxDiscountPercentagePf || 0)
      formMethods.setValue('name', selectedDealership?.name || '')
      formMethods.setValue('locationUf', selectedDealership?.locationUf || '')
      formMethods.setValue('available', Boolean(selectedDealership?.available))
      formMethods.setValue('minimumExceptionValue.allowException', selectedDealership.minimumExceptionValue?.allowException || false)
      isExceptionActive && formMethods.setValue('minimumExceptionValue.value', selectedDealership.minimumExceptionValue?.value ? transformMoney(selectedDealership.minimumExceptionValue?.value, 'toReal') : 0)
      if (selectedDealership?.resolutionClasses.length > 0) {
        formMethods.setValue('resolutionClasses', EMPTY_CLASSES_RESOLUTIONS.map((item: any) => {
          const myResolutionClass = selectedDealership?.resolutionClasses.find(resolution => resolution.class === item.class)

          if (myResolutionClass) {
            return {
              teValueCents: transformMoney(myResolutionClass.teValueCents, 'toReal'),
              tusdValueCents: transformMoney(myResolutionClass.tusdValueCents, 'toReal'),
              class: myResolutionClass.class,
              cofinsPercentage: myResolutionClass.cofinsPercentage,
              icmsPercentage: myResolutionClass.icmsPercentage,
              pisPercentage: myResolutionClass.pisPercentage
            }
          }

          return {
            ...item
          }
        }))
      }

    }
    if (!id) {
      formMethods.setValue('resolutionClasses', EMPTY_CLASSES_RESOLUTIONS)
    }
  }, [id, selectedDealership, isExceptionActive])

  if (!canCreateAndEditDealerships) {
    return <Navigate replace to='/app' />
  }

  if (dealershipIsLoading) return <FullPageLoader />

  return (
    <div>
      <Card sx={{ background: 'white', padding: '0 1rem', borderRadius: '1rem' }}>
        <ContentTitle
          title={`${id && selectedDealership ? `Alterar a concessionária ${selectedDealership.name}` : 'Cadastrar Concessionária'}`}
          description={`Complete os dados para ${id && selectedDealership ? 'alterar' : 'cadastrar'} Concessionária`}
          breadcrumbLinks={{ currentLink: `${id && selectedDealership ? 'Alterar' : 'Cadastrar'} concessionária`, links: [{ href: '/app/dealerships', label: 'Concessionária' }] }}
        />

        <FormProvider {...formMethods}>
          <Form onSubmit={formMethods.handleSubmit((data) => onSubmit(data))}>
            <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', gap: 3 }}>
              <Typography variant='h3' fontWeight={600}>
                Dados básicos
              </Typography>

              <Grid spacing={3} container>
                <Grid item xs={12} md={8}>
                  <Input name='name' label='Nome' />
                </Grid>

                <Grid item xs={12} md={8}>
                  <Select label='Estado' name='locationUf' options={brazilStates} />
                </Grid>
              </Grid>

              <Typography variant='h3' fontWeight={500}>Descontos</Typography>
              <Grid spacing={3} container>
                <Grid item xs={12} md={4}>
                  <Input mask='percentage' name='maxDiscountPercentagePf' label='Desconto para PF' />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Input mask='percentage' name='maxDiscountPercentagePj' label='Desconto para PJ' />
                </Grid>

                <Grid item xs={12}>
                  <Checkbox name='available' label='Disponível' />
                </Grid>
              </Grid>

              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '12px',
                background: '#FAFAFA',
                border: '1px solid #E6E6E6',
                padding: 4
              }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Typography variant='h3' fontWeight={500} color={isExeptionAvailable ? 'inherit' : '#CDCDCD'}>Valor mínimo de exceção</Typography>
                    <Status background={isExceptionActive ? '#C6F6D5' : '#E6E6E6'} text={isExceptionActive ? 'Ativo' : 'Inativo'} color={isExceptionActive ? '#367154' : '#999'} />
                  </Box>

                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Typography color='grey.400'>Habilitar valor mínimo de exceção</Typography>
                    <Switch name='minimumExceptionValue.allowException' />
                  </Box>
                </Box>

                <Divider sx={{ margin: '16px 0px 40px 0px' }} />

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1 }}>
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', width: '100%' }}>
                    <Box sx={{
                      display: 'flex',
                      width: '40px',
                      height: '40px',
                      borderRadius: '50%',
                      border: '1px solid #E6E6E6',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 1
                    }}>
                      <IconCurrencyDollar color={isExeptionAvailable ? '#999' : '#E6E6E6'} size={24} />
                    </Box>

                    <Box sx={{
                      gap: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%'
                    }}>
                      <Typography fontWeight={500} color={isExeptionAvailable ? 'inherit' : '#CDCDCD'}>Valor mínimo de conta</Typography>
                      <Typography color={isExeptionAvailable ? 'grey.700' : '#CDCDCD'}>Menor valor de conta aceito pela Desperta para esta concessionária</Typography>
                    </Box>
                  </Box>

                  <Input
                    mask='BRL'
                    fullWidth={false}
                    style={{ alignSelf: 'end' }}
                    label='Valor mínimo de conta'
                    disabled={!isExeptionAvailable}
                    name='minimumExceptionValue.value'
                    icons={{ end: { element: <IconCurrencyDollar color={isExeptionAvailable ? '#999' : '#E6E6E6'} /> } }}
                  />
                </Box>
              </Box>

              <Typography variant='h3' fontWeight={500}>Dados da calculadora</Typography>
              <Grid spacing={3} container>

                {/* <Grid item xs={12} md={4}>
                  <Select options={dealershipModalityOptions} name={`resolutionAneel.modality`} label='Modalidade' />
                </Grid> */}

                <Grid item xs={12} md={4}>
                  <Input name='resolutionAneel.validity' label='Vigência' />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Input customMask='####/####' name='resolutionAneel.resolutionHomologed' label='Número da resolução homologatória' />
                </Grid>

                <Grid item xs={12} md={8}>
                  <Input placeholder='https://www2.aneel.gov.br/cedoc/reh20233209ti.pdf' icons={{ end: { element: <IconLink color='#E6E6E6' /> } }} name='resolutionAneel.linkUrl' label='Link para a resolução homologatória' />
                </Grid>
              </Grid>

              {fields.map((field, index) => {
                return (
                  <Box key={field.id}>
                    <Typography variant='body1' sx={{ paddingBottom: '1rem' }} fontWeight={500}>Classe {field?.class?.toUpperCase()}</Typography>
                    <Grid spacing={3} container>

                      {/* <Grid display={{ md: 'none' }} item xs={12} md={4} >
                        <Input name={`resolutionClasses.${index}.class`} label='Classe' />
                      </Grid> */}
                      {/* <Grid item xs={12} md={4}>
                        <Select options={dealershipModalityOptions} name={`resolutionClasses.${index}.modality`} label='Modalidade' />
                      </Grid> */}

                      <Grid item xs={12} md={4}>
                        <Input mask='BRLToMWh' icons={{ end: { element: <IconCurrencyDollar color='#E6E6E6' /> } }} name={`resolutionClasses.${index}.tusdValueCents`} label='Valor da TUSD' />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Input mask='BRLToMWh' icons={{ end: { element: <IconCurrencyDollar color='#E6E6E6' /> } }} name={`resolutionClasses.${index}.teValueCents`} label='Valor da TE' />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Input mask='percentage' icons={{ end: { element: <IconPercentage color='#E6E6E6' /> } }} name={`resolutionClasses.${index}.icmsPercentage`} label='Alíquota ICMS' />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Input mask='percentage' icons={{ end: { element: <IconPercentage color='#E6E6E6' /> } }} name={`resolutionClasses.${index}.pisPercentage`} label='Alíquota PIS' />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Input mask='percentage' icons={{ end: { element: <IconPercentage color='#E6E6E6' /> } }} name={`resolutionClasses.${index}.cofinsPercentage`} label='Alíquota COFINS' />
                      </Grid>

                    </Grid>
                  </Box>
                )
              })}

              <Box sx={{
                width: '100%',
                padding: '2rem 0',
                display: 'flex',
                justifyContent: 'space-between',
                flexFlow: isLowerSm ? 'column-reverse' : 'row',
                gap: isLowerSm ? '1rem' : '0'
              }}>

                <Link style={{ width: '100%' }} to='/app/dealerships'>
                  <Button sx={{ maxWidth: isLowerSm ? '100%' : '160px' }} fullWidth disabled={createLoading || updateLoading} color='secondary'>
                    Cancelar
                  </Button>
                </Link>

                <Button
                  sx={{ maxWidth: isLowerSm ? '100%' : '220px' }}
                  startIcon={createLoading || updateLoading ? <CircularProgress size={24} color='inherit' /> : <IconCheck />}
                  fullWidth
                  type='submit'
                  disabled={createLoading || updateLoading}>
                  Enviar
                </Button>
              </Box>
            </Box>
          </Form>
        </FormProvider>
      </Card>
    </div>
  )
}
