import { Box, Typography } from '@mui/material'
import { useContract } from '~/contexts'
import { ActionButtons, ContractAdhesionTermCard, SingleAccountCard } from './components'
import { ContractInfo, PropsWithWizard } from '~/components'
import { useMemo } from 'react'
import { ERewardType, ETermStatus } from '~/graphql/types'
import { ContractManagementTermCard } from './components/ContractManagementTermCard'
import { isEmpty } from 'ramda'

export const Documents: React.FC<PropsWithWizard> = (props) => {
  const { contract } = useContract()

  const termOfAdhesion = useMemo(() => contract?.termOfAdhesion ? contract?.termOfAdhesion.find(term => term.active === true) : null, [contract])
  const termOfManagement = useMemo(() => contract?.termOfManagement ? contract?.termOfManagement.find(term => term.active) : null, [contract])
  const termOfManagementHistory = useMemo(() => contract?.termOfManagement?.filter(term => term.status === ETermStatus.replaced || term.status === ETermStatus.canceled), [contract])
  const termOfAdhesionHistory = useMemo(() => contract?.termOfAdhesion?.filter(term => term.status === ETermStatus.replaced || term.status === ETermStatus.canceled), [contract])
  const isCashback = contract?.reward?.type === ERewardType.cashback

  return (
    <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', gap: 3 }}>
      {contract?.proposalId && <ContractInfo contract={contract} />}

      <Typography variant='h3' fontWeight={500}>Termo de adesão</Typography>
      <ContractAdhesionTermCard term={termOfAdhesion} />

      {termOfAdhesionHistory && !isEmpty(termOfAdhesionHistory) && (
        <>
          <Typography variant='body1' fontWeight={500}>Histórico de termos de adesão</Typography>

          {termOfAdhesionHistory.map((term, index) => <ContractAdhesionTermCard key={`adhesion-term-history-${index}`} term={term} />)}
        </>
      )}

      <ContractManagementTermCard term={termOfManagement} />

      {termOfManagementHistory && !isEmpty(termOfManagementHistory) && (
        <>
          <Typography variant='body1' fontWeight={500}>Histórico de termos de gestão</Typography>

          {termOfManagementHistory.map((term, index) => <ContractManagementTermCard key={`term-history-${index}`} term={term} />)}
        </>
      )}

      {isCashback && (
        <>
          <Typography variant='h3' fontWeight={500}>Conta digital</Typography>
          <SingleAccountCard />
        </>
      )}

      <ActionButtons {...props} />
    </Box>
  )
}
